<template>
    <div id="user_list">
        <el-card>
            <div class="AdminTopOper">
                <el-space wrap :size="15">
                    <el-input v-model="pageData.searchKey" placeholder="请输入订单号"></el-input>
                    <el-input style="width: 120px" v-model="pageData.where.id" placeholder="请输入ID"></el-input>
                    <el-button type="primary" icon="Search" @click="orderSearce()">搜索</el-button>
                    <el-button type="primary" icon='Plus' @click="pageData.handleCreate()">新建</el-button>
                


                </el-space>
            </div>

            <!-- 表格主体 -->
            <el-table :data="pageData.rows">
                <el-table-column prop="id" label="编号" width="100" />

                <AdminTableButton :width="300">
                    <template #default="scope">
                        <!-- <el-button @click="handleAdminCard(scope.row.id, scope.row.classifyId)" icon="Rank">详情</el-button> -->
                        <el-button @click="click_copyUrl(scope.row)" icon="Rank">复制链接</el-button>
                    </template>
                </AdminTableButton>
            </el-table>

            <AdminPagination> </AdminPagination>
        </el-card>



        <!-- 新建与编辑dialog -->
        <el-dialog v-model="pageData.dialogVisible" title="新建" width="30%">
            <el-form ref="form" :model="pageData.row" label-width="100px" size='medium'>
                <el-form-item label="客户名称">
                    <el-input v-model="pageData.row.id"></el-input>
                </el-form-item>
                <el-form-item label="价格">
                    <el-input v-model="pageData.row.createdAt"></el-input>
                </el-form-item>
                <el-form-item label="单位">
                    <el-input v-model="pageData.row.createdAt"></el-input>
                </el-form-item>
            </el-form>

            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="pageData.dialogVisible = false">Cancel</el-button>
                    <el-button type="primary" @click="pageData.create()">Confirm</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>
  
<script setup >
import { ref, onMounted, reactive } from 'vue'
import Api from '@/network'
import { ElMessage, ElMessageBox } from 'element-plus'
const pageData = reactive(Api.adminPage('upFile'))
pageData.where.count = null
onMounted(pageData.getPage())

</script>
  
  

<style lang="less"></style>
  